// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#2659D41a', // for option hover bg-color
    primary: '#2659D4', // for selected option bg-color
    neutral10: '#2659D4', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const maxGrossSalary = 50000

export const toBRCurrency = value => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

export const toBRDate = value => value.toLocaleDateString('pt-BR', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })

export const formatCpf = document => document.substr(0, 3).concat('.', document.substr(3, 3), '.', document.substr(6, 3), '-', document.substr(9, 2))

export const formatFullName = fullName => {
  const splitStr = fullName.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return (splitStr.join(' '))
}

export const checkIfDateIsValid = date =>  (new Date(date) instanceof Date && !isNaN(new Date(date)))

export const createCompanyUrl = (url, store, userData) => {
  let urlTmp = url
  if (store.companies.companySelectedId !== userData['profile']['companyData']['companyGroupId']) {
    urlTmp = urlTmp.concat(`${urlTmp.indexOf('?') > 0 ? '&' : '?'}companyId=${store.companies.companySelectedId}`)
  }
  return urlTmp
}
export const formatCNPJ = cnpj => {
  if (cnpj.length !== 14) return cnpj
  const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/
  return cnpj.replace(cnpjRegex, "$1.$2.$3/$4-$5")
}

Array.prototype.max = function() {
  return Math.max.apply(null, this)
}

Array.prototype.min = function() {
  return Math.min.apply(null, this)
}

export const greatFormatMoney = (value) => {
  if (!value) {
    return 'R$ -'
  }

  const decimals = value.toString().split('.')[0]
  
  switch (decimals.length) {
    case 1: case 2: case 3:
      return `R$ ${decimals}`
    case 4:
      return `R$ ${decimals.substr(0, 1)}k`
    case 5:
      return `R$ ${decimals.substr(0, 2)}k`
    case 6:
      return `R$ ${decimals.substr(0, 3)}k`
    case 7:
      return `R$ ${decimals.substr(0, 1)}.${decimals.substr(1, 1)}M`
    case 8:
      return `R$ ${decimals.substr(0, 2)}.${decimals.substr(2, 1)}M`
    case 9:
      return `R$ ${decimals.substr(0, 3)}.${decimals.substr(3, 1)}M`
    case 10:
      return `R$ ${decimals.substr(0, 1)}B`
    default:
      return 'R$ 0'
  }
}

export const objectIsEmpty = (object) => {  
  return Object.keys(object).length === 0
}

export const userIsLogged = (user) => {
  return !objectIsEmpty(user) && !!user['profile'] && !!user['profile']['companyData'] && !!user['profile']['companyGroupId']
}

export const formatElegantDate = (date) => {
  const data = new Date(date)

  if (isNaN(data.getTime())) {
    return "Data inválida"
  }

  const dataLocal = new Date(data.getTime() + (data.getTimezoneOffset() * 60000))

  // Formata a data no formato desejado (02/10/2023 às 16:32)
  const dia = String(dataLocal.getDate()).padStart(2, '0')
  const mes = String(dataLocal.getMonth() + 1).padStart(2, '0') // Mês começa em 0
  const ano = dataLocal.getFullYear()
  const hora = String(dataLocal.getHours()).padStart(2, '0')
  const minuto = String(dataLocal.getMinutes()).padStart(2, '0')
  return `${dia}/${mes}/${ano} às ${hora}:${minuto}`
}