import { Input } from 'reactstrap'

export const TextQuestion = (props) => {
  return (<>
    <p><b>({props.currentPage - 1}/{props.pages - 1}) {props.question.title}</b></p>
    <Input 
      type='textarea'
      placeholder='Digite aqui'
      onChange={(e) => props.setSelectAlternatines([
        {
          alternativeId: 0,
          value: e.target.value
        }
      ])}
    />
  </>)
}

export default TextQuestion