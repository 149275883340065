import * as Sentry from '@sentry/react'

const logCapture = (type, context) => {
  const localUser = JSON.parse(localStorage.getItem('userData')) || {}
  
  Sentry.captureMessage(type, {
      user: {
          name: localUser['profile']['name'],
          username: localUser['userName']
      },
      level: 'info',
      context: [context]
  })
}

export default logCapture