import { useState } from 'react'
import { Row, Col, CustomInput } from 'reactstrap'

export const MultipleAlternativeQuestion = (props) => {
  const [listAlternatives, setListAlternatives] = useState([])

  const addAlternativeToList = (item) => {
    const list = listAlternatives
    list.push(item)
    const orderedList = list.sort((a, b) => a.alternativeId - b.alternativeId)
    setListAlternatives(orderedList)
    props.setSelectAlternatines(orderedList)
  }
  const removeAlternativeFromList = (item) => {
    const list = listAlternatives
    list.remove(item)
    setListAlternatives(list)
    props.setSelectAlternatines(list)
  }

  return (<>
    <p><b>({props.currentPage - 1}/{props.pages - 1}) {props.question.title}</b></p>
    {props.question.alternatives.map((x, i) => (
      <Row key={x.alternativeId}>
      <Col xl='12' className='mb-1 ml-2' key={x.alternativeId}>
        <CustomInput 
          type='checkbox'
          className='custom-control-primary'
          id={x.alternativeId}
          label={x.value}
          key={x.alternativeId}
          onClick={(e) => {
            e.target.checked ? addAlternativeToList(x) : removeAlternativeFromList(x)
          }}
        />
      </Col>
    </Row>      
    ))}
  </>)
}

export default MultipleAlternativeQuestion