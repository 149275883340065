// ** React Imports
import { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

// ** Custom Components
import Avatar from '@components/avatar'
import ModalNPS from '../../../components/modals/modal-nps'
import ModalDynamicSurvey from '../../../components/modals/modal-dinamic-survey'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleLogout } from '@store/actions/auth'
import {handleSurveySelected } from '@store/actions/survey'
import * as Sentry from "@sentry/react"

// ** Third Party Components
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'

// ** Default Avatar Image
// import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()


  // ** State
  const localUser = JSON.parse(localStorage.getItem('userData')) || {}
  const [userData, setUserData] = useState(localUser)
  const store = useSelector(state => state)
  const modalNpsRef = useRef()
  const modalDynamicSurveyRef = useRef()

  if (userData && userData['userName'] && userData['profile']) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        username: userData['userName'],
        name: userData['profile']['name']
      })
      scope.setTags({
        userName: userData['userName'],
        name: userData['profile']['name'],
        customerId: userData['profile']['customerId'],
        companyGroupId: userData['profile']['companyData']['companyGroupId']
      })
    })
  }

  const checkIfHasToAnswerNps = () => {
    if (userData && userData['profile'] && userData['profile']['customerId']) {
      return fetch(`${process.env.REACT_APP_BACKEND_URL}/surveys/nps/${userData['profile']['customerId']}/next-date`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${userData['accessToken']}`
        }
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        }
      }).then((res) => {
        const dateNow = new Date()
        const nextDate = new Date(res.nextDate)
        if (
          dateNow.getFullYear() === nextDate.getFullYear() &&
          dateNow.getMonth() === nextDate.getMonth() &&
          dateNow.getDate() === nextDate.getDate()
        ) {
          modalNpsRef.current.openModal()
        }
       }).catch((err) => {})
    }
  }

  const selectSurvey = (e, dispatch) => new Promise((resolve, reject) => {
    dispatch(handleSurveySelected({
      surveyId: e.surveyId,
      name: e.name,
      sumary: e.sumary,
      description: e.description,
      channel: e.channel,
      status: e.status,
      startDate: e.startDate,
      endDate: e.endDate,
      createdAt: e.createdAt,
      showToAllCompanies: e.showToAllCompanies,
      companies: e.companies,
      showOnLogin: e.showOnLogin,
      questions: e.questions
    }))
    resolve()
  })

  const checkIfHasActiveSurvey = () => {
    if (userData && userData['profile'] && userData['profile']['customerId']) {
      return fetch(`${process.env.REACT_APP_BACKEND_URL}/surveys/customer/${userData['profile']['customerId']}/companyGroup/${userData['profile']['companyData']['companyGroupId']}/channel/webEmpresa`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${userData['accessToken']}`
        }
      }).then(response => {
        if (response.status === 200) {
          return response.json()
        }
      }).then((res) => {
        if (res && res.data && res.data.length > 0) {
          selectSurvey(res.data[0], dispatch).then(() => {
            modalDynamicSurveyRef.current.openModal()
          })
        } else {
          checkIfHasToAnswerNps()
        }
       }).catch((err) => {})      
    }
  }

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
      checkIfHasActiveSurvey()
    }
  }, [store.companies.companySelectedId])

  //** Vars
  const userAvatar = defaultAvatar

  return (<>
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name font-weight-bold'>{(userData && userData['profile'] && userData['profile']['name']) || ''}</span>
          <span className='user-status'>RH</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag={Link} to='/login' onClick={() =>  dispatch(handleLogout()) }>
          <Power size={14} className='mr-75' />
          <span className='align-middle'>Sair</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    <ModalNPS 
      ref={modalNpsRef}
    />
    <ModalDynamicSurvey
      ref={modalDynamicSurveyRef}
    />
    </>)
}

export default UserDropdown
