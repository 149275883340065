const initialState = {
  surveyId: '',
  name: '',
  sumary: '',
  description: '',
  channel: '',
  status: '',
  startDate: '',
  endDate: '',
  createdAt: '',
  showToAllCompanies: false,
  companies: [],
  showOnLogin: false,
  questions: [
    {
      questionId: 0,
      type: '',
      title: '',
      alternatives: [
        {
          alternativeId: 0,
          value: ''
        }
      ]
    }
  ]
}

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_SURVEY_SELECTED':
      return {...state, ...action.value}
    default:
      return state
  }
}

export default surveyReducer