import { createContext, useContext, useState } from "react"

const AlertContext = createContext(false)

const AlertProvider = ({ children }) => {
  const [hasAlert, setHasAlert] = useState(false)
  const [messageAlert, setMessageAlert] = useState("")

  const toggleAlert = () => {
    setHasAlert((prev) => !prev)
  }

  const handleMessageAlert = (message) => {
    setMessageAlert(message)
  }

  const hideAlert = () => {
    setHasAlert(false)
  }

  return (
    <AlertContext.Provider value={{ hasAlert, toggleAlert, messageAlert, handleMessageAlert, hideAlert }}>
      {children}
    </AlertContext.Provider>
  )
}

const useAlertContext = () => {
  const context = useContext(AlertContext)
  if (!context) {
    throw new Error('useAlertContext deve ser usado dentro do AlertProvider')
  }
  return context
}

export { AlertProvider, useAlertContext }