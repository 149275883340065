import { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, Button, Input } from 'reactstrap'
import { ToastError } from '../../toast' 
import './styles.scss'

export const ModalNPS = forwardRef((props, ref) => {
  const localUser = JSON.parse(localStorage.getItem('userData')) || {}
  const [userData, setUserData] = useState(localUser)
  const [modalShowNps, setModalShowNps] = useState(false)
  const [grade, setGrade] = useState(null)
  const [reason, setReason] = useState(null)
  const listGrades = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]  

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalShowNps(true)
    }
  }))

  function changeValueOfReason(value) {
    if (value === grade) {
      setGrade(null)
    } else {
      setGrade(value)
    }
  }

  function sendNps() {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/surveys/nps`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${userData['accessToken']}` 
      },
      body: JSON.stringify({
        customer: {
          id: userData['profile']['customerId'],
          name: userData['profile']['name'],
          document: userData['profile']['document']
        },
        company: {
          id: userData['profile']['companyData']['companies'][0]['companyId'],
          name: userData['profile']['companyData']['companies'][0]['name'],
          document: userData['profile']['companyData']['companies'][0]['document']
        },
        product: 'webEmpresa',
        grade: (grade) ? grade : '',
        comment: reason
      })
    }).then(response => {
      if (response.status === 200) {
        setModalShowNps(false)
      }
    }).catch(error => {
      ToastError('Não foi possível enviar a avaliação')
    })
  }


  return (
    <Modal
    isOpen={modalShowNps} 
    toggle={() => setModalShowNps(!modalShowNps)} 
    className='modal-dialog-centered modal-size'
    >
      <ModalHeader toggle={() => setModalShowNps(!modalShowNps)} className='modal-size'>
        Pesquisa de Satisfação
      </ModalHeader>
      <ModalBody className='modal-size modal-white'>
        <p>De 0 a 10, quanto você indica a Pilla para um amigo ou familiar?</p>
        <Nav pills className='nav-pill-transparent'>
          {listGrades.map((x, i) => (
          <NavItem key={'item'.concat(x)}>
            <NavLink 
              className={`grades grade-${x.toString()}`}
              key={x} 
              active={x === grade}
              onClick={() => changeValueOfReason(x)}
            >
                {x}
              </NavLink>
          </NavItem>
        ) 
        )}
        </Nav>
        <p>Comentário</p>
        <Input 
          type='textarea'
          onChange={(e) => setReason(e.target.value) }
        />
      </ModalBody>
      <ModalFooter className='modal-size modal-white'>
      <Button 
        color='relief-primary' 
        onClick={() => sendNps()}
        disabled={grade === null}
      >
        Enviar Resposta
      </Button>
      </ModalFooter>
    </Modal>
  )
})

export default ModalNPS