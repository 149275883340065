import styled from "styled-components"

export const AlertStyle = styled.div`
  padding: 15px;
  background-color: #dc3545; /* Cor de fundo vermelha */
  color: #fff; /* Cor do texto branco */
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
`