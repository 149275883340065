import { AlertStyle } from "./styles"
import { useAlertContext } from "../../providers/AlertProvider"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

const Alert = ({ message }) => {
  const { hasAlert, handleMessageAlert, messageAlert, hideAlert } = useAlertContext()
  const state = useSelector((state) => state.alert)

  useEffect(() => {
    if (state.alert === null) {
      hideAlert()
    }
  }, [state])

  useEffect(() => {
    handleMessageAlert(message)
  }, [message])

  if (!hasAlert) {
    return <></>
  }

  return (
    <AlertStyle>{messageAlert}</AlertStyle>
  )
}

export default Alert