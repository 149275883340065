import ReactDOM from 'react-dom'
import Alert from "."
import { useAlertContext } from '../../providers/AlertProvider'

const AlertContainer = ({children}) => {
  const { messageAlert } = useAlertContext()
  return (
    <div>
      {ReactDOM.createPortal(
        <Alert message={messageAlert}/>,
        document.body
      )}
      {children}
    </div>
  )
}

export default AlertContainer