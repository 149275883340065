const initialState = {
  username: '',
  newPassword: ''
}

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FORGOT_PASSWORD':
      return {
        ...state,
        username: action.data.username,
        newPassword: action.data.newPassword
      }
    default: return state
  }
}

export default forgotPasswordReducer