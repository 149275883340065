// ** Dropdowns Imports
import { useEffect, useState, Fragment } from 'react'
import UserDropdown from './UserDropdown'

// ** Third Party Components
import { useHistory } from 'react-router-dom'
import { Menu, Layers } from 'react-feather'
import { NavItem, NavLink } from 'reactstrap'
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import { useSelector, useDispatch } from 'react-redux'
import { handleCompanySelected, handleCompanyList, handleCompanyGroupId, handleCompanySelectedIsGroup } from '@src/redux/actions/companies/index'
import { handleLogout } from '@src/redux/actions/auth'
import { DividerMenu } from './styles'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { formatCNPJ } from '../../../../utility/Utils'


const NavbarUser = props => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const store = useSelector(state => state)
  const localUser = JSON.parse(localStorage.getItem('userData')) || {}
  const [userData, setUserData] = useState(localUser)
  const [firstTitle, setFirstTitle] = useState('')
  const [secondTitle, setSecondTitle] = useState('')
  const [companiesOptions, setCompanyOptions] = useState(store.companies.companyList)
  const [selectedCompany, setSelectedCompany] = useState(store.companies.companyList[0])
  const [zendeskData, setZendeskData] = useState()

  function changeCompany(e) {
    dispatch(handleCompanySelected(e.value))
    dispatch(handleCompanySelectedIsGroup(store.companies.companyList.find(x => x.value === e.value).isGroup))
    setSelectedCompany([{ value: e.value, label: e.label, isGroup: e.isGroup }])
  }

  function setCompanies() {
    if (userData && userData['profile'] && userData['profile']['companyData'] && userData['profile']['companyData']['companies']) {
      const companyList = [{ value: userData['profile']['companyData']['companyGroupId'], label: 'Todas as Empresas', isGroup: true }]
      userData['profile']['companyData']['companies'].map((x, i) => {
        companyList.push({ value: x.companyId, label: `${x.name} - ${formatCNPJ(x.document)}`, isGroup: false })
      })
      dispatch(handleCompanyList(companyList))
      dispatch(handleCompanySelected(userData['profile']['companyData']['companyGroupId']))
      dispatch(handleCompanySelectedIsGroup(true))
      dispatch(handleCompanyGroupId(userData['profile']['companyData']['companyGroupId']))
      setCompanyOptions(companyList)
      setSelectedCompany(companyList[0])
    }
  }

  const getAuthZendesk = async () => {
    if (userData && userData['profile'] && userData['profile']['personId']) {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/hr/persons/${userData['profile']['personId']}/get-zendesk-config`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${userData['accessToken']}`
        }
      }).then(response => {
        if (response.status === 200) return response.json()
      }).then(res => {
        if (res.length > 0) {
          handleMessageAlert("Houve um problema ao conectar com o chat da Pilla!")
          toggleAlert()
        } else {
          setZendeskData(res)
        }
      }).catch((e) => {
        dispatch(handleLogout())
        history.push('/login')
      })
    }
  }

  useEffect(() => {
    if (store.companies.companyList === [] || store.companies.companySelectedId === '') {
      setCompanies()
    }
    switch (location.pathname) {
      case '/dashboard/geral':
        setFirstTitle('Dashboard')
        setSecondTitle('Geral')
        break
      case '/dashboard/saude-financeira':
        setFirstTitle('Dashboard')
        setSecondTitle('Saúde Financeira')
        break
      case '/extrato':
        setFirstTitle('Extrato')
        setSecondTitle('Desconto em Folha')
        break
      case '/colaboradores/lista':
        setFirstTitle('Colaboradores')
        setSecondTitle('Lista')
        break
      case '/colaboradores/admitir':
        setFirstTitle('Colaboradores')
        setSecondTitle('Adicionar Colaborador')
        break
      case '/colaboradores/editar':
        setFirstTitle('Colaboradores')
        setSecondTitle('Editar Colaborador')
        break
      case '/colaboradores/aprovacoes':
        setFirstTitle('Colaboradores')
        setSecondTitle('Aprovações')
        break
      default:
        setFirstTitle('')
        setSecondTitle('')
        break
    }
  })

  function logoutIfInsideBlackList(chat) {
    const result = JSON.parse(chat)
    if (result.Result && result.Result.length > 0) {
      const personIdList = result.Result
      if (userData['profile'] && userData['profile']['personId'] && personIdList.includes(userData['profile']['personId'])) {
        dispatch(handleLogout())
        history.push('/login')
      }
    }
  }

  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_BACKEND_URL}/hr/pending-logout`, {
      withCredentials: false
    })
    .withAutomaticReconnect()
    .build()
  connection.on("ReceiveHistory", function (chatHistory) {
    logoutIfInsideBlackList(chatHistory)
  })

  async function loadZendeskSnippet() {
    const head = document.getElementsByTagName("head")[0]
    const s = document.createElement('script')
    s.id = "ze-snippet"
    head.appendChild(s)
    s.type = 'text/javascript'
    s.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskData.zendeskId}`
    s.onload = () => {
      zE('messenger', 'loginUser', function (callback) {
        callback(zendeskData.accessToken)
      })
    }
  }

  useEffect(() => {
    if (zendeskData) {
      loadZendeskSnippet()
    }
  }, [zendeskData])

  useEffect(() => {
    try {
      connection
        .start()
        .then(() => console.log("Conectado ao SignalR"))
        .catch((error) => console.error(error))

      connection.on("ReceiveMessage", function (chatMessage) {
        logoutIfInsideBlackList(chatMessage)
      })
      getAuthZendesk()
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <Fragment>
      <ul className='navbar-nav d-xl-none d-flex align-items-center'>
        <NavItem className='mobile-menu mr-auto'>
          <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
            <Menu className='ficon' />
          </NavLink>
        </NavItem>
      </ul>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <p className="mt-10 user-name font-weight-bold" style={{ marginTop: 15, marginLeft: 10, fontSize: 20 }}>
          {firstTitle}
        </p>
        <DividerMenu />
        <p style={{ marginLeft: 10, paddingTop: 16 }}>
          {secondTitle}
        </p>
      </div>
      <ul className='nav navbar-nav align-items-center ml-auto'>
        <Layers />
        <div style={{ width: '250px', marginLeft: 10, marginRight: 5 }}>
          <Select
            value={selectedCompany}
            theme={selectThemeColors}
            className='react-select'
            classNamePrefix='select'
            defaultValue={companiesOptions[0]}
            options={companiesOptions}
            isClearable={false}
            onChange={(e) => {
              changeCompany(e)
            }}
          /></div>

        <UserDropdown />
      </ul>
    </Fragment>
  )
}
export default NavbarUser
