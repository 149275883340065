// ** React Imports
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/themes/prism-tomorrow.css'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import 'react-loading-skeleton/dist/skeleton.css'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker

// ** Pilla Styles
import './assets/css/Pilla.scss'
import { AlertProvider } from './providers/AlertProvider'
import AlertContainer from './Components/Alert/container'

import LazyApp from './App'

Sentry.init({
  dsn: 'https://f5fe308371a74ea199d6bc5e21c4cb51@o1170810.ingest.us.sentry.io/6299786',
  denyUrls: ['http://localhost:3000', 'https://localhost:3000', 'https://empresas.pilladev.com'],
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new BrowserTracing()],
  autoSessionTracking: true,
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AlertProvider>
        <AlertContainer>
          <ThemeContext>
            <LazyApp />
            <ToastContainer newestOnTop />
          </ThemeContext>
        </AlertContainer>
      </AlertProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
