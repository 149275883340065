import React, { Fragment } from 'react'
import { toast } from 'react-toastify'
import { AlertCircle, Check, X, Info } from 'react-feather'
import Avatar from '@components/avatar'

export const Toast = ({ type, message }) => {
  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          {type === 'success' && <Avatar size='sm' color='success' icon={<Check size={12} />} />}
          {type === 'error' && <Avatar size='sm' color='danger' icon={<X size={12} />} />}
          {type === 'warning' && <Avatar size='sm' color='warning' icon={<AlertCircle size={12} />} />}
          {type === 'info' && <Avatar size='sm' color='info' icon={<Info size={12} />} />}
          <h6 className='toast-title'>{message}</h6>
        </div>
      </div>
    </Fragment>
  )
}

export const ToastError = (message, hideProgress = true) => {
  return toast.error(<Toast message={message} type="error"/>, { hideProgressBar: hideProgress })
}

export const ToastSuccess = (message, hideProgress = true) => {
  return toast.success(<Toast message={message} type="success"/>, { hideProgressBar: hideProgress })
}

export const ToastWarning = (message, hideProgress = true) => {
  return toast.warning(<Toast message={message} type="warning"/>, { hideProgressBar: hideProgress })
}

export const ToastInfo = (message, hideProgress = true) => {
  return toast.info(<Toast message={message} type="info"/>, { hideProgressBar: hideProgress })
}
