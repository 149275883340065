const initialState = {
  email: '',
  userName: ''
}

const confirmationEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER_LOGIN_DETAILS':
      return { ...state, email: action.payload.email, userName: action.payload.userName }
    default: return state
  }
}

export default confirmationEmailReducer