import { Mail, Home, Heart, PieChart, FileText, Users, UserCheck } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home />,
    children: [
      {
        id: 'home',
        title: 'Geral',
        icon: <PieChart />,
        navLink: '/dashboard/geral'
      },
      {
        id: 'financialHealth',
        title: 'Saúde Financeira',
        icon: <Heart />,
        navLink: '/dashboard/saude-financeira'
      }
    ]
  },
  {
    header: 'Colaboradores'
  },
  {
    id: 'employees',
    title: 'Colaboradores',
    icon: <Users />,
    navLink: '/colaboradores/lista'
  },
  {
    id: 'employeesAproval',
    title: 'Aprovações',
    icon: <UserCheck />,
    navLink: '/colaboradores/aprovacoes'
  },
  {
    header: 'Extrato'
  },
  {
    id: 'extract',
    title: 'Descontos Em Folha',
    icon: <FileText />,
    navLink: '/extrato'
  }
]
