import { lazy } from 'react'

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/dashboard/geral'

const DashboardGeral = lazy(() => import('../../views/Dashboards/'))
const FinancialHealth = lazy(() => import('../../views/FinancialHealth/'))
const Extrato = lazy(() => import('../../views/Extract'))
const ColaboradoresLista = lazy(() => import('../../views/Employees'))
const ColaboradoresCriar = lazy(() => import('../../views/AddEmployee'))
const ColaboradoresEditar = lazy(() => import('../../views/EditEmployee'))
const ColaboradoresAprovar = lazy(() => import('../../views/EmployeeAprovals/'))
const Login = lazy(() => import('../../views/Login'))
const Error = lazy(() => import('../../views/Error'))
const ConfirmEmail = lazy(() => import('../../views/ConfirmEmail/'))
const ConfirmationCodeEmail = lazy(() => import('../../views/ConfirmationCodeEmail/'))
const MultiFactorAuth = lazy(() => import('../../views/MultiFactorAuth/'))
const ChangePassword = lazy(() => import('../../views/ChangePassword/'))
const ConfirmationCodeChangePassword = lazy(() => import('../../views/ConfirmationCodeChangePassword/'))
const UpdateLoginData = lazy(() => import('../../views/UpdateLoginData/'))

// ** Merge Routes
const Routes = [
  {
    path: '/dashboard/geral',
    component: DashboardGeral
  },
  {
    path: '/dashboard/saude-financeira',
    component: FinancialHealth
  },
  {
    path: '/extrato',
    component: Extrato
  },
  {
    path: '/colaboradores/lista',
    component: ColaboradoresLista
  },
  {
    path: '/colaboradores/admitir',
    component: ColaboradoresCriar
  },
  {
    path: '/colaboradores/editar',
    component: ColaboradoresEditar
  },
  {
    path: '/colaboradores/aprovacoes',
    component: ColaboradoresAprovar
  },
  {
    path: '/login',
    component: Login,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/confirm-email',
    component: ConfirmEmail,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/change-password',
    component: ChangePassword,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/confirmation-code-email',
    component: ConfirmationCodeEmail,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/multi-factor-auth',
    component: MultiFactorAuth,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/confirmation-code-password',
    component: ConfirmationCodeChangePassword,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/update-login-data',
    component: UpdateLoginData,
    layout: 'BlankLayout',
    meta: {
      authRoute: true
    }
  },
  {
    path: '/error',
    component: Error,
    layout: 'BlankLayout'
  }
]

export { DefaultRoute, TemplateTitle, Routes }
