import { Row, Col, CustomInput } from 'reactstrap'

export const SingleAlternativeQuestion = (props) => {
  return (<>
    <p><b>({props.currentPage - 1}/{props.pages - 1}) {props.question.title}</b></p>
    {props.question.alternatives.map((x, i) => (
      <Row key={x.alternativeId}>
        <Col xl={12} className='mb-1 ml-2' key={x.alternativeId}>
          <CustomInput 
            type='radio'
            className='custom-control-primary'
            id={x.alternativeId}
            name='single-alternative'
            label={x.value}
            key={x.alternativeId}
            onClick={() => props.setSelectAlternatines([
              {
                alternativeId: x.alternativeId,
                value: x.value
              }
            ])}
          />
        </Col>
      </Row>
    ))}
  </>)
}

export default SingleAlternativeQuestion