// ** Initial State
const initialState = {
  person: {
    personId: '',
    customerId: '',
    name: '', 
    document: '',
    email: '',
    mobilePhone: '',
    status: '',
    employeeInfo: {
      companyId: '',
      admissionDate: '',
      anotherDiscounts: 0,
      discountAlimony: 0,
      discountConsigned: 0,
      discountHealthCare: 0,
      employmentRelationship: '',
      grossSalary: 0,
      inss: 0,
      irrf: 0,
      netSalary: 0
    },
    blockedProducts: [],
    vacations: []
  }
}

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_EMPOLOYEE_SELECTED':
      return { ...state, person: action.value}
    default:
      return state
  }
}

export default companiesReducer