const initialState = {
  pageReleased: false
}

const multiFactorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGE_RELEASED_MULTI_FACTOR':
      return { ...state, pageReleased: action.payload.pageReleased }
    default: return state
  }
}

export default multiFactorReducer