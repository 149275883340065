import { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import IntroSurvey from './sections/introSurvey'
import SingleAlternativeQuestion from './sections/singleAlternativeQuestion'
import MultipleAlternativeQuestion from './sections/multipleAlternativeQuestion'
import TextQuestion from './sections/textQuestion'
import { ToastSuccess, ToastError } from '../../toast'
import logCapture from '../../sentry-component'

export const ModalDynamicSurvey = forwardRef((props, ref) => {
  const userData = JSON.parse(localStorage.getItem('userData')) || {}
  const [modalShowDynamicSurvey, setModalShowDynamicSurvey] = useState(false)
  const store = useSelector(state => state)
  const [surveyId, setSurveyId] = useState('')
  const [channel, setChannel] = useState('')
  const [title, setTitle] = useState()
  const [sumary, setSumary] = useState('')
  const [description, setDescription] = useState('')
  const [questions, setQuestions] = useState([])
  const [pages, setPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [questionsAnswered, setQuestionsAnswered] = useState([])
  const [selectedQuestion, setSelectedQuestion] = useState({
    questionId: 0,
    type: '',
    title: '',
    alternatives: []
  })
  const [selectAlternatines, setSelectAlternatines] = useState([])

  useImperativeHandle(ref, () => ({
    openModal() {
      setModalShowDynamicSurvey(true)
    }
  }))

  const selectSurvey = () => {
    setSurveyId(store.survey.surveyId)
    setTitle(store.survey.name)
    setChannel(store.survey.channel)
    setSumary(store.survey.sumary)
    setDescription(store.survey.description)
    setQuestions(store.survey.questions)
    setPages(store.survey.questions.length + 1)
    setCurrentPage(1)
  }

  const nextQuestion = () => {
    if (currentPage >= 2) {
      const list = questionsAnswered
      const chek = list.find(q => q.questionId === selectedQuestion.questionId)
      if (chek) {
        const index = list.findIndex((q => q.questionId === selectedQuestion.questionId))
        list[index].answers = selectAlternatines
      } else {
        list.push({
          questionId: selectedQuestion.questionId,
          type: selectedQuestion.type,
          title: selectedQuestion.title,
          answers: selectAlternatines
        })
      }
      setQuestionsAnswered(list)
    }
    const page = currentPage
    setSelectAlternatines([])
    setCurrentPage(page + 1)
    setSelectedQuestion(questions[page - 1])
  }

  const previousQuestion = () => {
    if (currentPage > 2) {
      const list = questionsAnswered
      list.pop()
      setQuestionsAnswered(list)
    }
    const page = currentPage
    setSelectAlternatines([])
    setCurrentPage(page - 1)
    setSelectedQuestion(questions[page - 3]) 
  }

  const aswerSurvey = () => {
    logCapture('AnswerSurvey', 'Respondeu à Pesquisa ativa')
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/surveys/${surveyId}/answer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${userData['accessToken']}`
      },
      body: JSON.stringify({
        surveyName: title,
        channel,
        customerId: userData['profile']['customerId'],
        customerName: userData['profile']['name'],
        customerDocument: userData['profile']['document'],
        companyId: userData['profile']['companyData']['companies'][0]['companyId'],
        companyName: userData['profile']['companyData']['companies'][0]['name'],
        companyGroupId:  userData['profile']['companyData']['companyGroupId'],
        questions: questionsAnswered
      })
    }).then((response) => {
      if (response.status === 200) {
        setModalShowDynamicSurvey(false)
        ToastSuccess('Pesquisa respondida com sucesso')
      }
    }).catch((err) => {
      ToastError('Ocorreu algum erro no envio da pesquisa')
    })
  }

  const finishSurvey = () => {
    const list = questionsAnswered
    list.push({
      questionId: selectedQuestion.questionId,
      type: selectedQuestion.type,
      title: selectedQuestion.title,
      answers: selectAlternatines
    })
    setQuestionsAnswered(list)
    aswerSurvey()
  }

  const loadBody = () => {
    if (currentPage > 0) {
      if (currentPage === 1) {
        return (
          <IntroSurvey
            sumary={sumary}
            description={description}
          />
        )
      } else if (selectedQuestion.type === 'singleAlternative') {
        return (
          <SingleAlternativeQuestion
            question={selectedQuestion}
            setSelectAlternatines={setSelectAlternatines}
            pages={pages}
            currentPage={currentPage}
          />
        )
      } else if (selectedQuestion.type === 'multipleAlternative') {
        return (
          <MultipleAlternativeQuestion
            question={selectedQuestion}
            setSelectAlternatines={setSelectAlternatines}
            pages={pages}
            currentPage={currentPage}
          />
        )
      } else if (selectedQuestion.type === 'text') {
        return (
          <TextQuestion
            question={selectedQuestion}
            setSelectAlternatines={setSelectAlternatines}
            pages={pages}
            currentPage={currentPage}
          />
        )
      }
    }
      return (<></>)
  }

  const loadFooter = () => {
    if (currentPage > 0 && currentPage <= pages) {
      if (currentPage === 1) {
        return (
          <Button color='relief-primary' onClick={() => nextQuestion()}>Começar Pesquisa</Button>)
      } else if (currentPage === pages) {
        return (
          <>
            <Button onClick={() => previousQuestion()}>Anterior</Button>
            <Button color='relief-primary' disabled={selectAlternatines.length === 0} onClick={() => finishSurvey()}>Enviar Pesquisa</Button>
          </>
        )
      } else {
        return (
          <>
            <Button onClick={() => previousQuestion()}>Anterior</Button>
            <Button color='relief-primary' disabled={selectAlternatines.length === 0} onClick={() => nextQuestion()}>Próximo</Button>
          </>
        )        
      }
    }
    return (<></>)
  }

  useEffect(() => {
    selectSurvey()
  }, [modalShowDynamicSurvey])

  return (
    <Modal
      isOpen={modalShowDynamicSurvey}
      toggle={() => setModalShowDynamicSurvey(!modalShowDynamicSurvey)}
      className='modal-dialog-centered'
    >
      <ModalHeader toggle={() => setModalShowDynamicSurvey(!modalShowDynamicSurvey)}>
        {title}
      </ModalHeader>
      <ModalBody>
        {loadBody()}
      </ModalBody>
      <ModalFooter>
        {loadFooter()}
      </ModalFooter>
    </Modal>
  )
})

export default ModalDynamicSurvey