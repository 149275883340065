// ** Icons Import
import { Heart } from 'react-feather'
import {urlPilla, urlPillaTaxesAndTarifs} from '../../../../utility/constants'

const Footer = () => {
  return (
    <p className='clearfix mb-0'>
      <span className='float-md-left d-block d-md-inline-block mt-25'>
        COPYRIGHT © {new Date().getFullYear()}{' '}
        <a href={urlPilla} target='_blank' rel='noopener noreferrer'>
          Pilla
        </a>
        <a href={urlPillaTaxesAndTarifs} target='_blank' rel='noopener noreferrer'>, Sobre nossas Tarifas e Limites</a>
      </span>
    </p>
  )
}

export default Footer
